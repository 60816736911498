@import 'assets/scss/mixin.scss';

:root {
  font-size: clamp(12px, calc(100vw * 16/ 1480), 16px);
}

body {
  margin: 0;
  font-family: Inter, sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &,
  * {
    font-family: Inter, sans-serif ;
    color: '#383C49';

    @include scroll-bar-style();
  }
}