@import '../../assets/scss/mixin.scss';

.rich-text-wrapper {
    border: 1px solid #d0d7de;
    border-radius: 10px;
    margin-top: 1.725rem;
    background-color: #FFFFFF;

    &--error {
        border: 1px solid #d32f2f;
    }

    .rich-text-editor {
        min-height: 150px;
        max-height: 250px;
        padding: 0 10px;
        overflow-y: auto;
        background-color: #FFFFFF;
        border-radius: 10px;

        @include scroll-bar-style();

        &::-webkit-scrollbar-thumb {
            height: 50px;
        }
    }

    .contract-rte{
        max-height: 550px!important;
    }

    .rich-text-editor-toolbar {
        border: none;
        background: #FFFFFF;
        border-bottom: 1px solid #DDE2E5;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .rdw-option-wrapper {
            margin: 0;
            min-width: 1.25rem;
            padding: 4px;
        }

        .rich-text-editor-toolbar-option {
            border: none;
            box-shadow: none;
            border: 1px solid transparent;

            &:hover {
                border: 1px solid #DDE2E5;
                background-color: #F9FAFC;
            }
        }

        .rdw-option-active {
            background-color: #E6E8EC;
        }

        .rich-text-editor-toolbar-option-dropdown {
            max-height: 150px;
            overflow-y: auto;

            @include scroll-bar-style();

            &::-webkit-scrollbar-thumb {
                height: 25px;
            }
        }
    }
}