.department-search {
    &-form {
        background-color: white;
        border-radius: 2rem;
        padding: 0 0 1rem;
        position: relative;
        width: 100%;

        &__grid {
            display: flex;
            padding: 2rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            gap: 3rem;

            &__item {
                width: 45%;
            }

            &__item-action {
                flex: 1;
            }

        }

        &__results {
            padding: 2rem;
            padding-bottom: 1rem;

            .department-info {
                width: 100%;
                height: 16rem;
                background-color: #F5F5F5;
                border-radius: 2rem;
                padding-bottom: 1rem;
            }
        }
    }
}