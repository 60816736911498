.courses-list {
  &-form {
    background-color: white;
    border-radius: 2rem;
    padding: 1rem 0;
    position: relative;
    width: 100%;

    &__grid {
      display: flex;
      padding: 2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      gap: 2rem;

      &__item {
        width: 20%;
      }
    }

    .icon-wrapper {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;

      .info-icon {
        height: 1.563rem;
        width: 1.563rem;
        align-self: center;
        cursor: pointer;
      }

      .reset-icon {
        height: 2.375rem;
        width: 2.375rem;
        cursor: pointer;
      }
    }

    &__results {
      padding: 2rem;
      padding-bottom: 1rem;

      .department-info {
        width: 100%;
        height: 16rem;
        background-color: #f5f5f5;
        border-radius: 2rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.name-wrapper {
  FormInput {
    background-color: red;
    // margin-right: '3px';
  }
}