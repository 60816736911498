.department-profile {
    &-forms {
        background-color: white;
        border-radius: 2rem;
        padding: 1rem;
        position: relative;

        div#{&}__grid {
            background-color: #f5f5f5;
            border-radius: 1rem;
            padding: 1rem;
        }
    }
}