.header {
    position: relative !important;
    padding: 1.875rem 2.5rem;
    color: #666666;
}

.headerRightSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .headerNotificationIcon {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background: #333333;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 0.7rem;
            height: 0.75rem;

            path {
                fill: #75E3FF;
            }
        }

    }

    .headerUserName {
        font-size: 1rem;
        font-weight: 400;
        color: #666666;
        padding: 0 0.5rem;
        cursor: pointer;
    }

    .headerLogoutLink {
        font-size: 1rem;
        font-weight: 600;
        color: #666666;
        padding: 0 2.5rem 0 0.5rem;
        cursor: pointer;
    }
}