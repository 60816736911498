@import './variables.scss';

@mixin app-width($property, $value) {
  #{$property}: calc(100vw * #{$value} / 1440);

  @media (width >=1441px) {
    #{$property}: #{$value}px;
  }
}

@mixin scroll-bar-style($height: 40px) {
  scrollbar-width: thin;
  scrollbar-color: #e0e0e0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 100px;
    height: $height;
    border: 1px solid transparent;
    background-clip: content-box;
  }
}